


































import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import { Product } from '@/models/internal';
import FormBase from '@/components/FormBase/FormBase.vue';
import type { FormBaseSchema } from '@/lib/types';
import { IFormValidator } from '@/lib/interfaces';

@Component({
  name: 'ProductsForm',
  components: {
    FormBase,
  },
})
export class ProductsForm extends Vue implements IFormValidator {
  @Ref('form')
  public readonly formRef!: any;

  @Prop({ required: true })
  protected readonly schema!: FormBaseSchema;

  @Prop({ required: false, default: () => ({ cols: 12, md: 6 }) })
  protected readonly col!: any;

  @Prop({ required: true })
  private readonly model!: Product;

  public valid = false;

  @Watch('model', { deep: true })
  protected watchModel() {
    this.valid = this.validate();
  }

  public mounted() {
    this.valid = this.validate();
  }

  public validate(): boolean {
    return this.formRef.validate();
  }

  public resetValidation(): void {
    return this.formRef.resetValidation();
  }

  protected reset(): void {
    this.formRef.reset();
    this.$nextTick(() => {
      this.valid = this.validate();
    });
  }

  /**
   * Validate form then emit save event when button is clicked
   */
  protected save(): boolean {
    const valid = this.validate();
    if (!valid) return false;

    this.$emit('save', this.model);

    return true;
  }
}

export default ProductsForm;
