








































import { ITableView } from '@/lib/interfaces';
import { Collection, Product } from '@/models/internal';
import { UserService, ProductService, CollectionService } from '@/services';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Utility } from '@/tools/Utility';
import { debounce } from 'lodash';
import TableRowActions from '@/components/Table/TableRowActions.vue';
import ProductsDataTable from '@/views/Dashboard/Products/components/ProductsDataTable/ProductsDataTable.vue';
import ProductsListControls from '@/views/Dashboard/Products/components/ProductsListControls.vue';
import { renderBatchDialog } from '@/components/Dialog/BatchProcessDialog.vue';
import { Logger } from '@/tools/Logger';
import ContextBarManager from '@/components/ContextBar/classes/ContextBarManager';
import { IUpdateContextBar } from '@/components/ContextBar/interfaces/UpdateContextBar.interface';

@Component({
  name: 'ProductsList',
  components: {
    TableRowActions,
    ProductsDataTable,
    ProductsListControls,
  },
})
export default class ProductsList extends Vue implements ITableView<Product>, IUpdateContextBar {
  public data: Product[] = [];

  public selected: Product[] = [];

  public collections?: Collection[];

  public headers: DataTableHeader<any>[] = [
    { text: 'Thumbnail', value: 'image_url', sortable: false, filterable: false, width: 100 },
    { text: 'SKU', value: 'sku', sortable: false, filterable: false, width: 200 },
    { text: 'Title', value: 'title', sortable: false, filterable: false },
    { text: 'Collections', value: 'collections', width: 120, sortable: false, filterable: false },
    { text: 'Inventory', value: 'inventory', width: 120, sortable: false, filterable: false },
    { text: 'Auto-Approval Limit', value: 'auto_approval_limit', width: 120, sortable: false, filterable: false },
    { text: 'Alerts', value: 'alerts', width: 120, sortable: false, filterable: false },
    { text: 'Actions', value: 'actions', width: 120, sortable: false, filterable: false },
  ];

  public formatDate = Utility.formatDate;

  private readonly collectionService: CollectionService = CollectionService.getInstance();

  private readonly productService: ProductService = ProductService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'ProductsList' });

  private page: number | string = 1;

  private perPage: number | string = 10;

  private totalRecords: number | string = -1;

  private search = '';

  private readonly loading = { overlay: false, table: false, sync: false };

  private debouncedInit = debounce(this.init, 500);

  private getBatchSelection = renderBatchDialog.bind(this);

  private allItemsSelected = false;

  @Watch('selected')
  protected watchSelected() {
    this.updateContextBar();
  }

  @Watch('page')
  @Watch('perPage')
  protected watchPagination() {
    this.init();
  }

  @Watch('search')
  protected watchSearch() {
    this.loading.table = true;
    this.selected = [];
    this.page = 1;
    this.debouncedInit();
  }

  public created() {
    this.init();
    this.updateContextBar();
  }

  /**
   * Update the ContextBar with view-related actions
   */
  public updateContextBar() {
    const actions = [
      {
        color: 'primary',
        icon: 'mdi-cloud-refresh',
        label: 'Sync Products',
        callback: this.onClickSyncProductData,
      },
    ];

    if (this.selected.length > 0) {
      actions.push(
        {
          label: 'Add to Collections',
          callback: () => this.addProductsToCollections(this.selected),
          icon: 'mdi-plus-box-multiple',
          color: 'success',
        }, {
          label: 'Remove from Collections',
          callback: () => this.removeProductsFromCollections(this.selected),
          icon: 'mdi-minus-box-multiple',
          color: 'error',
        },
      );
    }

    ContextBarManager.setActions(...actions);
  }

  public async fetchData() {
    const params: any = {
      authentication_token: this.userService.getActiveToken(),
      page: this.page,
      per_page: this.perPage,
    };

    if (this.search) {
      params.sku_containing = this.search;
    }

    // If the pagination has the `All` flag, delete pagination params
    if (this.perPage === -1) {
      params.page = 1;
      params.per_page = this.totalRecords;
    }

    return this.productService.api.find(params);
  }

  public async init(): Promise<void> {
    this.loading.table = true;

    const { products, total } = await this.fetchData();
    this.totalRecords = total;

    await this.productService.insert({ data: products });
    this.data = this.productService.findIn(products.map((data: any) => data.id));

    this.loading.table = false;
  }

  protected async viewProduct(product: Product) {
    return this.$router.push({
      name: 'products-view',
      params: {
        id: String(product.id),
      },
    });
  }

  protected async editProduct(product: Product) {
    return this.$router.push({
      name: 'products-edit',
      params: {
        id: String(product.id),
      },
    });
  }

  protected canRead(product: Product) {
    return this.$ability.can('read', product);
  }

  protected canUpdate(product: Product) {
    return this.$ability.can('update', product);
  }

  protected async fetchCollections() {
    const { collections } = await this.collectionService.api.find({
      authentication_token: this.userService.getActiveToken(),
      show_blacklist_collections: true,
    });

    return collections;
  }

  protected async removeProductsFromCollections(products: Product[]) {
    if (!this.collections) {
      this.collections = await this.fetchCollections();
    }

    const { sources, targets } = await this.getBatchSelection<Product, Collection>({
      sources: products,
      targets: this.collections,
      title: 'Remove Products from Collections',
      subtitle: 'Select one or more Collections to remove your selected Products from',
      sourceType: 'Products',
      targetType: 'Collections',
      targetText: 'name',
      targetValue: 'id',
    });
    try {
      let result;

      if (this.allItemsSelected) {
        result = await this.collectionService.batchRemoveProducts(
          sources,
          targets,
          {
            select_all: this.allItemsSelected,
            sku_containing: this.search,
          },
        );
      } else {
        result = await this.collectionService.batchRemoveProducts(sources, targets);
      }

      this.$genify.notify('Saved!', 'success');
      return result;
    } catch (error) {
      this.$genify.alert('Error! Unable to save changes', 'error');
      this.logger.error(error);
    } finally {
      this.selected = [];
    }
  }

  protected async addProductsToCollections(products: Product[]) {
    if (!this.collections) {
      this.collections = await this.fetchCollections();
    }

    const { sources, targets } = await this.getBatchSelection<Product, Collection>({
      sources: products,
      targets: this.collections,
      title: 'Add Products to Collections',
      subtitle: 'Select one or more Collections to add your selected Products to',
      sourceType: 'Products',
      targetType: 'Collections',
      targetText: 'name',
      targetValue: 'id',
    });
    try {
      let result;

      if (this.allItemsSelected) {
        result = await this.collectionService.batchAddProducts(
          sources,
          targets,
          {
            select_all: this.allItemsSelected,
            sku_containing: this.search,
          },
        );
      } else {
        result = await this.collectionService.batchAddProducts(sources, targets);
      }

      this.$genify.notify('Saved!', 'success');
      return result;
    } catch (error) {
      this.$genify.alert('Error! Unable to save changes', 'error');
      this.logger.error(error);
    } finally {
      this.selected = [];
    }
  }

  /**
   * Sync latest Product data
   */
  protected async onClickSyncProductData() {
    this.loading.table = true;
    this.loading.sync = true;
    this.selected = [];
    this.page = 1;
    try {
      const synced = await this.productService.api.sync({
        authentication_token: this.userService.getActiveToken(),
      });
      if (synced) this.$genify.notify('Product data synced successfully!', 'success');
      this.init();
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('Error! Failed to sync Product data.', 'error');
    } finally {
      this.loading.table = false;
      this.loading.sync = false;
    }
  }
}
