







































































import Product from '@/models/Product';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ProductsImageThumb } from '@/components/Products/ProductsImageThumb.vue';

@Component({
  name: 'ProductsInfo',
  components: {
    ProductsImageThumb,
  },
})
export class ProductsInfo extends Vue {
  @Prop({ required: false, default: true })
  protected readonly useUpload!: boolean;

  @Prop({ required: false, default: true })
  protected readonly useLightbox!: boolean;

  @Prop({ required: true })
  private product!: Product;

  protected loading = false;

  protected get productSku() {
    return this.product.sku;
  }

  protected get productTitle() {
    return this.product.meta?.title || this.product.title;
  }

  protected get productDescription() {
    return this.product.meta?.description || this.product.description;
  }
}

export default ProductsInfo;
