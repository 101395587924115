



































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { IEditFormView } from '@/lib/interfaces';
import { ProductForm } from '@/models/forms';
import { prepareData, Product } from '@/models/internal';
import { ProductService, UserService } from '@/services';
import { ProductsForm } from './components/ProductsForm.vue';
import { ProductsInfo } from './components/ProductsInfo.vue';
import { Logger } from '@/tools/Logger';
import { Sync } from 'vuex-pathify';
import {
  showAlert,
  getAlerts,
} from './components/ProductsDataTable/components/ProductsAlert.vue';
import ContextBarManager from '@/components/ContextBar/classes/ContextBarManager';
import { IUpdateContextBar } from '@/components/ContextBar/interfaces/UpdateContextBar.interface';

@Component({
  name: 'ProductsEdit',
  components: {
    ProductsForm,
    ProductsInfo,
  },
})
export default class ProductsEdit extends Vue implements IEditFormView<Product>, IUpdateContextBar {
  @Prop()
  public id!: string;

  @Prop()
  public fromRouteName!: string;

  @Ref('product-form')
  public readonly formRef!: any;

  @Sync('context/overlay@visible')
  protected loading!: boolean;

  @Ref('alerts')
  private readonly alertsRef!: HTMLDivElement;

  public form!: ProductForm;

  private readonly productService: ProductService = ProductService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'ProductsEdit' });

  private get model() {
    return this.form.getModel();
  }

  private set model(model) {
    this.form.setModel(model);
  }

  protected get schema() {
    return this.form.getSchema();
  }

  protected get modelTitle() {
    return this.model.meta?.title || this.model.title;
  }

  protected get modelDescription() {
    return this.model.meta?.description || this.model.description;
  }

  public created() {
    this.init();
    this.updateContextBar();
  }

  public async init() {
    this.loading = true;

    try {
      const data = await this.fetchData(this.id);
      if (!data) throw Error('Unable to fetch Product from server');
      const product = new Product(prepareData(data, ProductService.mapData));
      this.form = new ProductForm(product);
      this.showAlerts();
    } catch (error) {
      this.logger.error(error);
    }

    this.loading = false;
  }

  public async save(): Promise<any> {
    this.loading = true;

    const token = this.userService.getActiveToken();
    if (!token) throw Error('Unable to get token from active User');
    try {
      await this.productService.api.update({
        id: this.id,
        authentication_token: token,
        product: this.model,
      });
      if (this.fromRouteName === 'collections-view') {
        this.$router.back();
      } else {
        this.$router.push({ name: 'products-view', params: { id: this.id } });
      }
      this.$genify.alert('Product successfully updated!', 'success');
    } catch (error) {
      this.logger.error(error);
      throw Error('Sorry, there was an error while saving this record.');
    } finally {
      this.loading = false;
    }
  }

  public fetchData(id: string): any {
    return this.productService.api.findOne({
      id,
      authentication_token: this.userService.getActiveToken(),
    });
  }

  public updateContextBar() {
    ContextBarManager.setActions({
      icon: 'mdi-content-save-outline',
      color: 'primary',
      label: 'Save',
      callback: this.save,
    });
  }

  /**
   * Show alerts for this Product if anything is raising flags
   */
  protected showAlerts() {
    if (!this.model || !this.model.meta) return;
    const alerts = getAlerts(this.model);
    alerts.forEach((alert) =>
      showAlert.bind(this)(this.alertsRef, alert),
    );
  }
}
