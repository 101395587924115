import { Form } from './Form';
import { Product } from '@/models/internal';
import { FormBaseSchema } from '@/lib/types';

/**
 * Form structure representing the Product model
 */
export class ProductForm extends Form<Product> {
  public schema: FormBaseSchema = {
    meta: {
      label: 'Metadata',
      type: 'group',
      schema: {
        category: {
          label: 'Category',
          type: 'text',
          prependIcon: 'mdi-shape-outline',
          rules: [
            Form.rules.required(),
          ],
          flex: 12,
          required: true,
        },
        unit_of_measure_type: {
          label: 'Unit Of Measure Type',
          type: 'text',
          prependIcon: 'mdi-ruler',
          rules: [
            Form.rules.required(),
          ],
          flex: 12,
          required: true,
        },
        unit_of_measure_size: {
          label: 'Unit Of Measure Size',
          type: 'text',
          prependIcon: 'mdi-numeric',
          rules: [
            Form.rules.required(),
          ],
          flex: 12,
          required: true,
        },
        max_quantity: {
          label: 'Max Quantity',
          type: 'text',
          prependIcon: 'mdi-numeric',
          rules: [
            Form.rules.required(),
          ],
          flex: 12,
          required: true,
        },
        description: {
          label: 'Description',
          type: 'textarea',
          rules: [
            Form.rules.required(),
          ],
          prependIcon: 'mdi-text-box-outline',
          flex: 12,
          required: true,
        },
        title: {
          label: 'Title (Optional)',
          type: 'text',
          prependIcon: 'mdi-pencil',
          rules: [
            Form.rules.maxLength(128),
          ],
          flex: 12,
        },
        sort_weight: {
          label: 'SKU Priority Sequence (Optional)',
          type: 'text',
          prependIcon: 'mdi-order-bool-descending',
          rules: [
            Form.rules.numeric(),
          ],
          class: 'mb-4',
          flex: 12,
          // persistentHint: true,
          hint: 'The lower the value, the higher on the order form this Product (and others in it\'s category) will appear',
        },
        peoplesoft: {
          label: 'Peoplesoft (Optional)',
          type: 'text',
          prependIcon: 'mdi-medical-bag',
          rules: [],
          flex: 12,
        },
        meditech: {
          label: 'Meditech (Optional)',
          type: 'text',
          prependIcon: 'mdi-medical-bag',
          rules: [],
          flex: 12,
        },
        auto_approval_quantity_limit: {
          label: 'Auto Approval Limit (Optional)',
          type: 'text',
          prependIcon: 'mdi-stamper',
          rules: [
            Form.rules.numeric(),
          ],
          flex: 12,
          hint: `When site auto-approval is set to 'custom' mode, checks to ensure quantity
                   ordered does not exceed this value (unless a custom value has been set).`,
        },
      } as FormBaseSchema,
    },
  };

  public getDefault(field: string) {
    return field;
  }
}
