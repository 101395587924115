




























import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import ApprovalStatusIndicator from '@/components/StatusIndicator/ApprovalStatusIndicator.vue';
import { prepareData, Product } from '@/models/internal';
import { ProductService, UserService } from '../../../services';
import { Logger } from '@/tools/Logger';
import { IModelView } from '@/lib/interfaces';
import { Utility } from '@/tools/Utility';
import { ProductsInfo } from './components/ProductsInfo.vue';
import { InfoListSection as InfoListSectionType, Maybe } from '@/lib/types';
import InfoListSection from '@/components/InfoList/InfoListSection.vue';
import {
  showAlert,
  getAlerts,
} from './components/ProductsDataTable/components/ProductsAlert.vue';
import ContextBarManager from '@/components/ContextBar/classes/ContextBarManager';
import { IUpdateContextBar } from '@/components/ContextBar/interfaces/UpdateContextBar.interface';

@Component({
  name: 'ProductsView',
  components: {
    ApprovalStatusIndicator,
    InfoListSection,
    ProductsInfo,
  },
})
export default class ProductsView extends Vue implements IModelView<Product>, IUpdateContextBar {
  @Prop({ required: true })
  private readonly id!: string | number;

  @Ref('alerts')
  private readonly alertsRef!: HTMLDivElement;

  /**
   * Product object and data to be rendered
   */
  public data: Maybe<Product> = null;

  /**
   * Loading state handlers
   */
  public loading = false;

  private readonly productService: ProductService = ProductService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'ProductsView' });

  public created() {
    this.init();
    this.updateContextBar();
  }

  /**
   * Fetch Product data from the server
   */
  public async fetchData(): Promise<any> {
    return this.productService.api.findOne({
      id: this.id,
      authentication_token: this.userService.getActiveToken(),
    });
  }

  public async init(): Promise<void> {
    try {
      this.loading = true;
      const data = await this.fetchData();
      if (data) this.data = new Product(prepareData(data, ProductService.mapData));
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading = false;
    }

    this.$nextTick(() => this.showAlerts());
  }

  public updateContextBar() {
    ContextBarManager.setActions({
      icon: 'mdi-pencil-outline',
      color: 'primary',
      label: 'Edit',
      to: { name: 'products-edit', params: { id: String(this.id) } },
    });
  }

  /**
   * All the columns in the Product details card to be iterated
   * on by section
   */
  protected getSections(): InfoListSectionType[] {
    if (!this.data) return [];

    return [
      {
        label: 'Product Information',
        items: [
          {
            label: 'SKU',
            value: this.data.sku,
            icon: 'mdi-identifier',
          },
          {
            label: 'Title',
            value: this.data.title,
            icon: 'mdi-format-title',
          },
          {
            label: 'Description',
            value: this.data.description,
            icon: 'mdi-text-box-outline',
          },
          {
            label: 'Prices',
            value: 'Available Pricelists:',
            icon: 'mdi-currency-usd',
            siblings: this.data.source_references.values.map((value: any) => {
              return {
                label: value.pricelist_name,
                value: Utility.formatCurrency(value.price),
              };
            }),
          },
          {
            label: 'Season',
            value: this.data.season,
            icon: 'mdi-archive',
          },
          {
            label: 'Image URL',
            value: this.data.image_url || '',
            icon: 'mdi-image',
          },
          {
            label: 'Image URL (Small)',
            value: this.data.image_url_small || '',
            icon: 'mdi-image',
          },
          {
            label: 'Created At',
            value: this.data.created_at,
            icon: 'mdi-text-box-outline',
            siblings: [
              {
                label: 'Updated At',
                value: this.data.updated_at,
              },
            ],
          },
        ],
      },
      {
        label: 'Metadata',
        items: [
          {
            label: 'Category',
            value: this.data.meta.category,
            icon: 'mdi-format-list-bulleted-type',
            siblings: [
              {
                label: 'Sort Weight',
                value: this.data.meta.sort_weight,
              },
              {
                label: 'Max Quantity',
                value: this.data.meta.max_quantity,
              },
              {
                label: 'Unit Of Measure Size',
                value: this.data.meta.unit_of_measure_size,
              },
              {
                label: 'Unit Of Measure Type',
                value: this.data.meta.unit_of_measure_type,
              },
              {
                label: 'Description',
                value: this.data.meta.description,
              },
              {
                label: 'Title (Optional)',
                value: this.data.meta.title,
              },
              {
                label: 'Peoplesoft (Optional)',
                value: this.data.meta.peoplesoft,
              },
              {
                label: 'Meditech (Optional)',
                value: this.data.meta.meditech,
              },
              {
                label: 'Auto Approval Limit',
                value: this.data.meta.auto_approval_quantity_limit,
              },
              {
                label: 'Valid Metadata',
                value: this.data.meta.valid,
                class: (this.data.meta.valid) ? 'font-weight-bold success--text' : 'font-weight-bold error--text',
              },
            ],
          },
        ],
      },
      {
        label: 'Inventory',
        items: [
          {
            label: 'Quantity',
            value: this.data.inventory.quantity ?? 0,
            icon: 'mdi-counter',
          },
          {
            label: 'Stop Selling',
            value: this.data.inventory.stop_selling ?? false,
            icon: 'mdi-alert-octagon-outline',
            class: (!this.data.inventory.stop_selling) ? 'font-weight-bold success--text' : 'font-weight-bold warning--text',
          },
        ],
      },
    ];
  }

  /**
   * Show alerts for this Product if anything is raising flags
   */
  private showAlerts() {
    if (!this.data || !this.data.meta) return;

    // Render any issues present for Product's data
    const alerts = getAlerts(this.data);
    alerts.forEach((alert) =>
      showAlert.bind(this)(this.alertsRef, alert),
    );
  }
}
